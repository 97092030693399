export const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const googleAPILanguage = process.env.REACT_APP_GOOGLE_API_LANGUAGE;
export const googleAPIRegion = process.env.REACT_APP_GOOGLE_API_REGION;


const build = process.env.REACT_APP_ENV;

console.log("build", build);

let authUrlTemp = 'https://paypaycompras.com.br/dev/';
let authApiUrlTemp = 'https://paypaycompras.com.br/dev/public/api/';

switch (build) {
  case "production":
    authUrlTemp = 'https://paypaycompras.com.br/dev/';
    authApiUrlTemp = 'https://paypaycompras.com.br/dev/public/api/';

    break;

  case "dev":
    authUrlTemp = 'https://localhost/';
    authApiUrlTemp = 'https://localhost/public/api/';

    break;

  default:
    authUrlTemp = 'https://paypaycompras.com.br/dev/';
    authApiUrlTemp = 'https://paypaycompras.com.br/dev/public/api/';
    break;
}

export const rootUrl = authUrlTemp;
export const apiUrl = authApiUrlTemp;

