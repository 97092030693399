import { actionTypes } from '../actions/address.action'

const initialState = {
    addresses: [],
    current_address: {},
    new_address: {
        user_id: null,
        house: '',
        tag: '',
        address: '',
        latitude: null,
        longitude: null,
    },
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.INDEX_ADDRESSES:
            state.addresses =  payload
            return { ...state, ...payload }

        case actionTypes.CURRENT_ADDRESS:
            state.current_address = payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE:
            return { 
                ...state, 
                new_address: {
                    ...state.new_address,
                    ...payload
                }
            };

        case actionTypes.SUCCESS:
                return { 
                    ...state, 
                    success: payload
                };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
